import React, {
  useContext,
  forwardRef,
  useState,
  useRef,
  useCallback,
  ReactNode,
  FC,
  HTMLAttributes,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation, Link } from 'react-router-dom';
import classNames from 'classnames';
import { useWindowSize } from 'react-use';
import { NavHashLink } from 'react-router-hash-link';
import { Manager, Popper, Reference } from 'react-popper';
// @ts-ignore
import useEventOutside from '@omtanke/react-use-event-outside';
import { useTranslation } from 'react-i18next';
import Icon from '../../components/icon/Icon';
import ThemeContext from '../../contexts/themeContext';
import Collapse from '../../components/bootstrap/Collapse';
import useDarkMode from '../../hooks/useDarkMode';
import { TIcons } from '../../type/icons';
import LightenDarkenColor from '../../utility/lightenDarkenColor';
import { UserContext } from '../../components/klassenrooster/UserContext';
import Dropdown, {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from '../../components/bootstrap/Dropdown';
import Button from '../../components/bootstrap/Button';
import { useTour } from '@reactour/tour';

interface IListProps extends HTMLAttributes<HTMLUListElement> {
  id?: string;
  children?: ReactNode;
  className?: string;
  ariaLabelledby?: string;
  parentId?: string;
  rootId?: string;
  horizontal?: boolean;
}
export const List = forwardRef<HTMLUListElement, IListProps>(
  (
    {
      id,
      children,
      className,
      ariaLabelledby,
      parentId,
      rootId,
      horizontal,
      ...props
    },
    ref
  ) => {
    return (
      <ul
        ref={ref}
        id={id}
        className={classNames(
          'navigation',
          { 'navigation-menu': horizontal },
          className
        )}
        aria-labelledby={ariaLabelledby}
        data-bs-parent={
          parentId === `${rootId}__${rootId}`
            ? `#${rootId}`
            : (parentId && `#${parentId}`) || null
        }
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {children}
      </ul>
    );
  }
);
List.displayName = 'List';
List.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  ariaLabelledby: PropTypes.string,
  parentId: PropTypes.string,
  rootId: PropTypes.string,
  horizontal: PropTypes.bool,
};
List.defaultProps = {
  id: undefined,
  children: null,
  className: undefined,
  ariaLabelledby: undefined,
  parentId: undefined,
  rootId: undefined,
  horizontal: false,
};

interface IItemProps {
  children?: ReactNode;
  to?: string;
  title?: string;
  icon?: TIcons;
  id?: string;
  parentId?: string;
  rootId: string;
  isHorizontal?: boolean;
  notification?: boolean | string;
  isMore?: boolean;
  hide?: boolean;
  dataTour?: string;
  activeItem?: string;
  user?: any;
  setActiveItem?(...args: unknown[]): unknown;
}
export const Item: FC<IItemProps> = ({
  children,
  to,
  title,
  icon,
  id,
  parentId,
  rootId,
  isHorizontal,
  notification,
  isMore,
  hide,
  dataTour,
  ...props
}) => {
  const { darkModeStatus } = useDarkMode();
  const { width } = useWindowSize();
  const { setAsideStatus, setLeftMenuStatus, setRightMenuStatus } =
    useContext(ThemeContext);
  const { user } = useContext(UserContext);
  // eslint-disable-next-line react/prop-types
  const ACTIVE = props.activeItem === id;
  const [triggerReset, setTriggerReset] = useState(false);
  const handleClick = () => {
    if (typeof props.setActiveItem !== 'undefined') {
      // eslint-disable-next-line react/prop-types, @typescript-eslint/no-unused-expressions
      ACTIVE ? props.setActiveItem(null) : props.setActiveItem(id);
    }
  };

  const linkHandleClick = () => {
    // For Mobile Design
    if (width < Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE))
      setAsideStatus(false);
    setLeftMenuStatus(false);
    setRightMenuStatus(false);
    setTriggerReset(!triggerReset);
  };

  const ANCHOR_LINK_PATTERN = /^#/i;
  const location = useLocation();

  // For aside menu
  const here =
    typeof to === 'string' && to !== '/' && location.pathname.includes(to);
  // For top menu
  const match = to !== '/' && location.pathname === to;

  const { t } = useTranslation('menu');

  const LINK_CLASS = classNames(
    'navigation-link',
    'navigation-link-pill',
    'bg-transparent',
    {
      collapsed: !!children && !isHorizontal,
      active: isHorizontal ? match : here,
    }
  );

  const INNER = (
    <>
      <span className='navigation-link-info'>
        {icon && <Icon className='navigation-icon' icon={icon} />}
        {title && <span className='navigation-text'>{t(title)}</span>}
      </span>
      {(!!children || !!notification) && (
        <span className='navigation-link-extra'>
          {!!notification && (
            <Icon
              icon='Circle'
              className={classNames(
                'navigation-notification',
                {
                  [`text-${notification}`]: typeof notification === 'string',
                  'text-danger': typeof notification !== 'string',
                },
                'animate__animated animate__heartBeat animate__infinite animate__slower'
              )}
            />
          )}
          {!!children && (
            <Icon className='navigation-arrow' icon='ChevronRight' />
          )}
        </span>
      )}
    </>
  );

  const WITHOUT_CHILD =
    !children &&
    !hide &&
    ((typeof to === 'string' && ANCHOR_LINK_PATTERN.test(to) && (
      <NavHashLink
        data-tour={dataTour}
        className={LINK_CLASS}
        to={to}
        onClick={linkHandleClick}
      >
        {INNER}
      </NavHashLink>
    )) || (
      <NavLink
        // @ts-ignore
        className={classNames(LINK_CLASS, ({ isActive }) =>
          isActive ? 'active' : ''
        )}
        to={`../${to}`}
        onClick={linkHandleClick}
        data-tour={dataTour}
      >
        {INNER}
      </NavLink>
    ));

  useEffect(() => {
    document
      .querySelectorAll('.aside .navigation-link')
      .forEach((el: any) => (el.style.backgroundColor = user?.primary_color));

    const item2: any = document.querySelector('.aside .navigation-link.active');

    if (item2 && user?.primary_color) {
    }
  }, [triggerReset]);

  // Dropdown
  const dropdownRef = useRef(null);

  const dropdownButtonRef = useRef(null);
  const setButtonRef = useCallback((node: null, ref: (arg0: any) => any) => {
    dropdownButtonRef.current = node;
    return ref(node);
  }, []);

  const dropdownListRef = useRef(null);
  const setListRef = useCallback((node: null, ref: (arg0: any) => any) => {
    dropdownListRef.current = node;
    return ref(node);
  }, []);

  const [dropdownStatus, setDropdownStatus] = useState(false);

  const dropdownButtonHandleClick = () => {
    setDropdownStatus(!dropdownStatus);
  };

  // Clicking outside to close
  const closeMenu = useCallback(() => {
    setDropdownStatus(false);
  }, []);
  useEventOutside(dropdownRef, 'mousedown', closeMenu);
  useEventOutside(dropdownRef, 'touchstart', closeMenu);

  if (children) {
    // submenu && in header
    if (isHorizontal) {
      return (
        <Manager>
          <li
            ref={dropdownRef}
            className={classNames('navigation-item', 'dropdown', {
              'navigation-item-more': isMore,
            })}
          >
            <Reference>
              {({ ref }) => (
                <span
                  // @ts-ignore
                  ref={(node) => setButtonRef(node, ref)}
                  id={`${rootId}__${id}--link`}
                  className={LINK_CLASS}
                  // data-bs-toggle='dropdown'
                  // data-bs-target={`#${rootId}__${id}`}
                  aria-expanded={dropdownStatus}
                  aria-controls={`${rootId}__${id}`}
                  role='button'
                  tabIndex={-1}
                  onClick={dropdownButtonHandleClick}
                  onKeyDown={dropdownButtonHandleClick}
                >
                  {INNER}
                </span>
              )}
            </Reference>
            {dropdownStatus && (
              <Popper
                placement='bottom-start'
                modifiers={[
                  {
                    name: 'flip',
                    options: {
                      fallbackPlacements: [`bottom-end`, `bottom-start`],
                    },
                  },
                ]}
              >
                {({ ref, style, placement }) => (
                  <List
                    // @ts-ignore
                    ref={(node) => setListRef(node, ref)}
                    style={style}
                    data-placement={placement}
                    id={`${rootId}__${id}`}
                    className={classNames(
                      'dropdown-menu',
                      {
                        'dropdown-menu-dark': darkModeStatus,
                      },
                      'show'
                    )}
                    ariaLabelledby={`${rootId}__${id}--link`}
                    rootId={rootId}
                    parentId={`${rootId}__${parentId}`}
                    onMouseLeave={() => setDropdownStatus(false)}
                  >
                    {children}
                  </List>
                )}
              </Popper>
            )}
          </li>
        </Manager>
      );
    }
    // submenu && in aside
    return (
      <li className='navigation-item' data-tour={dataTour}>
        <span
          id={`${rootId}__${id}--link`}
          className={LINK_CLASS}
          // data-bs-toggle='collapse'
          // data-bs-target={`#${rootId}__${id}`}
          aria-expanded={ACTIVE}
          aria-controls={`${rootId}__${id}`}
          role='button'
          tabIndex={-1}
          onClick={handleClick}
          onKeyDown={handleClick}
        >
          {INNER}
        </span>
        <Collapse isOpen={ACTIVE} key={`${id}`} isChildClone>
          <List
            id={`${rootId}__${id}`}
            ariaLabelledby={`${rootId}__${id}--link`}
            rootId={rootId}
            parentId={`${rootId}__${parentId}`}
            onMouseLeave={closeMenu}
          >
            {children}
          </List>
        </Collapse>
      </li>
    );
  }
  // without submenu
  return <li className='navigation-item'>{WITHOUT_CHILD}</li>;
};
Item.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
  id: PropTypes.string,
  parentId: PropTypes.string,
  rootId: PropTypes.string.isRequired,
  isHorizontal: PropTypes.bool,
  notification: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  isMore: PropTypes.bool,
  hide: PropTypes.bool,
};
Item.defaultProps = {
  children: null,
  to: undefined,
  title: undefined,
  icon: undefined,
  id: undefined,
  parentId: undefined,
  isHorizontal: false,
  notification: false,
  isMore: false,
  hide: false,
};

interface INavigationLineProps {
  className?: string;
}

export const NavigationLine: FC<INavigationLineProps> = ({ className }) => {
  return <hr className={classNames('navigation-line', className)} />;
};

NavigationLine.propTypes = {
  className: PropTypes.string,
};
NavigationLine.defaultProps = {
  className: undefined,
};

interface INavigationTitleProps extends HTMLAttributes<HTMLSpanElement> {
  className?: string;
  children: ReactNode;
  icon?: string;
  open?: boolean;
}
export const NavigationTitle: FC<INavigationTitleProps> = ({
  className,
  children,
  icon,
  open,
  ...props
}) => {
  return (
    <li className='navigation-item d-flex align-items-center justify-content-between'>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <span
        className={classNames(
          'navigation-title d-flex align-items-center  text-uppercase',
          className
        )}
        {...props}
      >
        {/*  <Icon icon={icon} className='card-icon fs-4'></Icon>*/}

        {children}
      </span>
      <Icon
        icon='ExpandMore'
        className={`card-icon ${open ? 'rotate-180' : 'rotate-360'}`}
      ></Icon>
    </li>
  );
};
NavigationTitle.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  icon: PropTypes.string,
  open: PropTypes.bool,
};
NavigationTitle.defaultProps = {
  className: undefined,
  icon: undefined,
};

interface INavigationProps {
  horizontal?: boolean;
  menu: {
    [key: string]: {
      id?: string | number;
      text?: string;
      path?: string;
      icon?: TIcons;
      isDisable?: boolean;
      subMenu?: {
        [key: string]: {
          id?: string | number;
          text?: string;
          path?: string;
          icon?: TIcons;
          isDisable?: boolean;
        };
      } | null;
    };
  };
  menuHorizontal?: any;
  id: string;
  className?: string;
}
const Navigation = forwardRef<HTMLElement, INavigationProps>(
  ({ menu, horizontal, menuHorizontal, id, className, ...props }, ref) => {
    const [activeItem, setActiveItem] = useState(undefined);
    const [open, setOpen] = useState(true);
    const { t } = useTranslation('menu');
    const { isOpen } = useTour();

    useEffect(() => {
      if (isOpen) {
        setOpen(Boolean(isOpen));
      }
    }, [isOpen]);

    function fillMenu(
      data:
        | {
            id?: string | number;
            text?: string;
            path?: string;
            dataTour?: string;
            icon?: TIcons;
            isDisable?: boolean;
            subMenu?:
              | {
                  id?: string | number;
                  text?: string;
                  path?: string;
                  icon?: TIcons;
                  isDisable?: boolean;
                }[]
              | undefined;
          }[]
        | any,
      parentId: string,
      rootId: string,
      isHorizontal: boolean | undefined,
      isMore: boolean | undefined
    ) {
      return Object.keys(data).map((item) =>
        data[item].path ? (
          <Item
            key={data[item].id}
            rootId={rootId}
            id={data[item].id}
            title={data[item].text}
            icon={data[item].icon}
            to={`${data[item].path}`}
            parentId={parentId}
            isHorizontal={isHorizontal}
            setActiveItem={setActiveItem}
            activeItem={activeItem}
            notification={data[item].notification}
            hide={data[item].hide}
            dataTour={data[item]?.dataTour}
          >
            {!!data[item].subMenu &&
              fillMenu(
                data[item].subMenu,
                data[item].id,
                rootId,
                isHorizontal,
                undefined
              )}
          </Item>
        ) : (
          <></>
        )
      );
    }

    return (
      // @ts-ignore
      // eslint-disable-next-line react/jsx-props-no-spreading
      <nav ref={ref} aria-label={id} className={className} {...props}>
        <List id={id} horizontal={horizontal}>
          {!horizontal && (
            <>
              {!menu[Object.keys(menu)[0]].path ? (
                <>
                  <NavigationTitle
                    key={menu[Object.keys(menu)[0]].id}
                    onClick={() => setOpen(!open)}
                    className='fs-5 text-white cursor-pointer my-2'
                    icon={menu[Object.keys(menu)[0]].icon}
                    open={open}
                  >
                    {menu[Object.keys(menu)[0]].text}
                  </NavigationTitle>
                  <Collapse key={menu[Object.keys(menu)[0]].id} isOpen={open}>
                    {fillMenu(menu, id, id, horizontal, undefined)}
                  </Collapse>
                </>
              ) : (
                fillMenu(menu, id, id, horizontal, undefined)
              )}
            </>
          )}
          {horizontal && (
            <>
              {fillMenu(menu, id, id, horizontal, undefined)}
              <Item
                rootId={`other-${id}`}
                title={t('More')}
                icon='MoreHoriz'
                isHorizontal
                isMore
              >
                {fillMenu(menu, `other-${id}`, `other-${id}`, false, true)}
              </Item>
            </>
          )}
        </List>
      </nav>
    );
  }
);
Navigation.displayName = 'Navigation';
Navigation.propTypes = {
  horizontal: PropTypes.bool,
  key: PropTypes.number,

  // @ts-ignore
  menu: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    text: PropTypes.string,
    path: PropTypes.string,
    icon: PropTypes.string,
    isDisable: PropTypes.bool,
    subMenu: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        text: PropTypes.string,
        path: PropTypes.string,
        icon: PropTypes.string,
        isDisable: PropTypes.bool,
      })
    ),
  }).isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
};
Navigation.defaultProps = {
  horizontal: false,
  className: undefined,
};

interface INavigationHeaderProps extends HTMLAttributes<HTMLSpanElement> {
  className?: string;
  icon?: string;
  menu: {
    [key: string]: {
      id?: string | number;
      text?: string;
      path?: string;
      icon?: TIcons;
      isDisable?: boolean;
      subMenu?: {
        [key: string]: {
          id?: string | number;
          text?: string;
          path?: string;
          icon?: TIcons;
          isDisable?: boolean;
        };
      } | null;
    };
  };
}
export const NavigationHeader: FC<INavigationHeaderProps> = ({
  className,
  icon,
  menu,
  ...props
}) => {
  const [open, setOpen] = useState(false);

  return (
    <li className='navigation-item d-flex align-items-center justify-content-start'>
      <Dropdown isOpen={open} setIsOpen={setOpen}>
        <DropdownToggle hasIcon={false}>
          <Button
            tag='a'
            className='border-0 bg-transparent d-flex align-items-center pe-0'
          >
            {menu[Object.keys(menu)[0]].text}
          </Button>
        </DropdownToggle>

        <DropdownMenu>
          {Object.keys(menu)
            .slice(1)
            .map((e) => {
              return (
                <DropdownItem key={menu[e].text}>
                  <NavLink key={menu[e].text} to={`/${menu[e].path!}`}>
                    {menu[e].text}
                  </NavLink>
                </DropdownItem>
              );
            })}
        </DropdownMenu>
      </Dropdown>
      <NavLink
        to={'/workers'}
        className='me-4 ms-4 text-decoration-none'
        style={{ fontWeight: '600', fontSize: '1rem', color: '#323232' }}
      >
        Werkers
      </NavLink>
      <NavLink
        to={'/settings'}
        className='text-decoration-none'
        style={{ fontWeight: '600', fontSize: '1rem', color: '#323232' }}
      >
        Instellingen
      </NavLink>
    </li>
  );
};
NavigationHeader.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  // @ts-ignore
  menu: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    text: PropTypes.string,
    path: PropTypes.string,
    icon: PropTypes.string,
    isDisable: PropTypes.bool,
    subMenu: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        text: PropTypes.string,
        path: PropTypes.string,
        icon: PropTypes.string,
        isDisable: PropTypes.bool,
      })
    ),
  }).isRequired,
};
NavigationHeader.defaultProps = {
  className: undefined,
  icon: undefined,
};

export default Navigation;
