import { useCallback, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { UserContext } from '../../../components/klassenrooster/UserContext';
import useDeviceScreen from '../../../hooks/useDeviceScreen';
import Header, { HeaderLeft, HeaderRight } from '../../../layout/Header/Header';
import { NavigationHeader } from '../../../layout/Navigation/Navigation';
import { headerDropMenu } from '../../../menu';
import CommonHeaderChat from './CommonHeaderChat';
import CommonHeaderRight from './CommonHeaderRight';

const SummaryHeader = () => {
  const { width } = useDeviceScreen();
  const location = useLocation();
  const { user } = useContext(UserContext);

  const pathname = location.pathname;

  const returnTitle = useCallback(() => {
    if (pathname === '/') {
      return 'Home';
    } else if (pathname === '/current-schedule') {
      return 'Huidige rooster';
    } else if (pathname === '/schedule-overview') {
      return 'Overzicht';
    } else if (pathname === '/homework') {
      return 'Taken';
    } else if (pathname === '/workers') {
      return 'Werkers';
    } else if (pathname === '/add-worker') {
      return 'Werker Toevoegen';
    } else if (pathname === '/settings') {
      return 'Instellingen';
    } else if (pathname === '/weekschedule') {
      return 'Weekrooster';
    } else if (pathname === '/basisschedule') {
      return 'Basisrooster';
    } else if (pathname === '/subject-schedule') {
      return 'Vaklesrooster';
    }
  }, [pathname]);

  return (
    <Header>
      <HeaderLeft>
        <span className='fw-bold text-black'>{returnTitle()}</span>
      </HeaderLeft>
      <HeaderRight>
        {user?.role_id !== 1 && <NavigationHeader menu={headerDropMenu} />}
      </HeaderRight>
      <CommonHeaderRight afterChildren={<CommonHeaderChat />} />
    </Header>
  );
};

export default SummaryHeader;
