import { useContext, useState } from 'react';

import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IButtonProps } from '../../../components/bootstrap/Button';
import { UserContext } from '../../../components/klassenrooster/UserContext';
import useLogout from '../../../hooks/logout/useLogout';
import useChangeSecondaryColorBySchool from '../../../hooks/school/useChangeSecondaryColorBySchool';
import useDarkMode from '../../../hooks/useDarkMode';

const CommonHeaderChat = () => {
  useChangeSecondaryColorBySchool();
  const [state, setState] = useState<boolean>(false);
  const { darkModeStatus, setDarkModeStatus } = useDarkMode();
  const { user, setUser } = useContext(UserContext);
  const styledBtn: IButtonProps = {
    color: darkModeStatus ? 'dark' : 'light',
    hoverShadow: 'default',
    isLight: !darkModeStatus,
    size: 'lg',
  };
  const { handleLogout } = useLogout();
  return (
    <>
      <div
        style={{ marginLeft: 'auto', borderRadius: '0.3125rem' }}
        onClick={() => handleLogout()}
        className='px-3  col-auto d-flex flex-column align-items-center justify-content-center pb-1 transition-base cursor-pointer bg-light-hover'
      >
        {/*  <Icon
            forceFamily='bootstrap'
            icon='Power'
            size='2x'
            color='danger'
            className='font-bold text-bold'
          />*/}
        <FontAwesomeIcon
          icon={faPowerOff}
          style={{ fontSize: 20, height: 20 }}
          className='cursor-pointer fa-icon  py-2'
        />
      </div>
      {/* 
        <Dropdown>
          <DropdownToggle hasIcon={false}>
            <div
              className='col d-flex align-items-center cursor-pointer'
              onClick={() => setState(!state)}
              role='presentation'
            >
              <div className='me-3'>
                <div className='text-end'>
                  <div className='fw-bold fs-6 mb-0'>{`${user?.name}`}</div>
                  <div className='text-muted'>
                    <small>{user?.user_authorization}</small>
                  </div>
                </div>
              </div>
              <div className='position-relative'>
                <Avatar src={Logo} size={48} />

                <span className='position-absolute top-85 start-85 translate-middle badge border border-2 border-light rounded-circle bg-success p-2'>
                  <span className='visually-hidden'>Online user</span>
                </span>
              </div>
            </div>
          </DropdownToggle>
        
          <DropdownMenu
            isAlignmentEnd
            size='lg'
            className='py-0 overflow-hidden'
          >
            <div className='row g-0'>
              <div
                className={
                  'text-danger bg-danger col-12 p-4 d-flex justify-content-center fw-bold fs-5 border-bottom'
                }
              >
                Menu
              </div>
   
              <div
                onClick={() => handleLogout()}
                className={classNames(
                  'col-12 p-4 transition-base cursor-pointer bg-light-hover',
                  'border-end',
                  { 'border-dark': darkModeStatus }
                )}
              >
                <div className='d-flex flex-column align-items-center justify-content-center'>
                  <Icon icon='Logout' size='3x' color='danger' />
                  <span>Uitloggen</span>
                  {/* <small className='text-muted'>Settings</small>
                </div>
              </div>
            </div>
          </DropdownMenu>
        </Dropdown>*/}
    </>
  );
};

export default CommonHeaderChat;
