import React, { useCallback, useState, useContext } from 'react';
import axios from 'axios';
import { API_URL } from '../../constants';
import { useForm } from 'react-hook-form';
import { UserContext } from '../../components/klassenrooster/UserContext';
import { useNavigate } from 'react-router-dom';
import { toastError } from '../../components/klassenrooster/utility/toastHandler';

const useLogout = () => {
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);

  const handleLogout = useCallback(async () => {
    try {
      await axios.delete(`${API_URL}/teacher/logout`);
      setUser(null);
      navigate('/login');
    } catch (e: any) {
      toastError(e?.response?.data?.msg);
    }
  }, [navigate, setUser]);

  return {
    handleLogout,
  };
};

export default useLogout;
