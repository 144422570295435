import React from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';

export const RoleBasedProtected = ({ user, types, children, path }: any) => {
  const location = useLocation();

  if (!user) {
    return <Navigate to={`/login`} replace />;
  } else if (!types.includes(parseInt(user?.type))) {
    return <Navigate to={`/login`} replace />;
  }
  return children;
};

export const LoginHandler = ({ user, types, children }: any) => {
  if (!user || (user && !types.includes(parseInt(user?.type)))) {
    return children;
  } else if (user && types.includes(parseInt(user?.type))) {
    return <Navigate to='/' replace />;
  }
  return children;
};
