import React, { lazy, useContext } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import contents from '../../routes/contentRoutes';
import {
  adminPages,
  dashboardMenu,
  demoPages,
  homeworkMenu,
  settingsMenu,
  studentsMenu,
  workersMenu,
} from '../../menu';
import { RoleBasedProtected, LoginHandler } from '../../routes/routeAuths';
import { UserContext } from '../../components/klassenrooster/UserContext';

const PAGE_404 = lazy(() => import('../../pages/presentation/auth/Page404'));

const LOGIN = {
  TEACHER_LOGIN: lazy(() => import('../../pages/presentation/auth/Login')),
  ADMIN_LOGIN: lazy(() => import('../../pages/presentation/auth/AdminLogin')),
};

const FORGOT = {
  FORGOT: lazy(() => import('../../pages/presentation/auth/ForgotPassword')),
  FORGOT_RESET: lazy(
    () => import('../../pages/presentation/auth/ResetForgotPassword')
  ),
};

const LANDING = {
  ADMIN_DASHBOARD: lazy(
    () => import('../../pages/dashboard/AdminDashboardPage')
  ),
  DASHBOARD: lazy(() => import('../../pages/dashboard/DashboardPage')),
  WORKER: lazy(() => import('../../pages/worker/WorkerPage')),
  ADD_WORKER: lazy(() => import('../../pages/worker/AddWorkerPage')),
};
const SETTINGS = {
  SETTING: lazy(() => import('../../pages/settings/SettingsPage')),
};
const SCHEDULE = {
  OVERVIEWSCHEDULE: lazy(
    () => import('../../pages/schedule/OverviewSchedulePage')
  ),
  CURRENTSCHEDULE: lazy(
    () => import('../../pages/schedule/CurrentSchedulePage')
  ),
  WEEKSCHEDULE: lazy(() => import('../../pages/schedule/WeekschedulePage')),
  BASISSCHEDULE: lazy(() => import('../../pages/schedule/BasisSchedulePage')),
  SUBJECTSCHEDULE: lazy(
    () => import('../../pages/schedule/SubjectSchedulePage')
  ),
};
const HOMEWORK = {
  HOMEWORK: lazy(() => import('../../pages/homework/HomeworkPage')),
};
const AUTH = {
  PAGE_404: lazy(() => import('../../pages/presentation/auth/Page404')),
};
const ADMIN = {
  SCHOOLS: lazy(() => import('../../pages/admin/SchoolsPage')),
  WORKERS: lazy(() => import('../../pages/admin/WorkersPage')),
};

const ContentRoutes = () => {
  const { user, setUser } = useContext(UserContext);

  const returnCorrectDashboard = () => {
    if (user?.type == 2) {
      return <LANDING.ADMIN_DASHBOARD />;
    } else {
      return <LANDING.DASHBOARD />;
    }
  };

  return (
    <Routes>
      <Route
        key={dashboardMenu.dashboard.id}
        path={dashboardMenu.dashboard.path}
        element={
          <RoleBasedProtected user={user} types={[1, 2]}>
            {returnCorrectDashboard()}
          </RoleBasedProtected>
        }
      />
      <Route
        key={studentsMenu.overview.id}
        path={studentsMenu.overview.path}
        element={
          <RoleBasedProtected user={user} types={[1]}>
            <LANDING.WORKER />
          </RoleBasedProtected>
        }
      />
      <Route
        key={studentsMenu.add_worker.id}
        path={studentsMenu.add_worker.path}
        element={
          <RoleBasedProtected user={user} types={[1]}>
            <LANDING.ADD_WORKER />
          </RoleBasedProtected>
        }
      />
      <Route
        key={settingsMenu.setting.id}
        path={settingsMenu.setting.path}
        element={
          <RoleBasedProtected user={user} types={[1]}>
            <SETTINGS.SETTING />
          </RoleBasedProtected>
        }
      />
      <Route
        key={workersMenu.overview.id}
        path={workersMenu.overview.path}
        element={
          <RoleBasedProtected user={user} types={[1]}>
            <SCHEDULE.OVERVIEWSCHEDULE />
          </RoleBasedProtected>
        }
      />
      <Route
        key={workersMenu.currentschedule.id}
        path={workersMenu.currentschedule.path}
        element={
          <RoleBasedProtected user={user} types={[1]}>
            <SCHEDULE.CURRENTSCHEDULE />
          </RoleBasedProtected>
        }
      />
      <Route
        key={settingsMenu.weekschedule.id}
        path={settingsMenu.weekschedule.path}
        element={
          <RoleBasedProtected user={user} types={[1]}>
            <SCHEDULE.WEEKSCHEDULE />
          </RoleBasedProtected>
        }
      />
      <Route
        key={settingsMenu.basisschedule.id}
        path={settingsMenu.basisschedule.path}
        element={
          <RoleBasedProtected user={user} types={[1]}>
            <SCHEDULE.BASISSCHEDULE />
          </RoleBasedProtected>
        }
      />
      <Route
        key={settingsMenu.subjectschedule.id}
        path={settingsMenu.subjectschedule.path}
        element={
          <RoleBasedProtected user={user} types={[1]}>
            <SCHEDULE.SUBJECTSCHEDULE />
          </RoleBasedProtected>
        }
      />
      <Route
        key={workersMenu.overviewHomework.path}
        path={workersMenu.overviewHomework.path}
        element={
          <RoleBasedProtected user={user} types={[1]}>
            <HOMEWORK.HOMEWORK />
          </RoleBasedProtected>
        }
      />
      <Route
        key={demoPages.login.id}
        path={demoPages.login.path}
        element={
          <LoginHandler user={user} types={[1]}>
            <LOGIN.TEACHER_LOGIN />
          </LoginHandler>
        }
      />
      <Route
        key={demoPages.admin_login.id}
        path={demoPages.admin_login.path}
        element={
          <LoginHandler user={user} types={[2]}>
            <LOGIN.ADMIN_LOGIN />
          </LoginHandler>
        }
      />
      <Route
        key={adminPages.schools.id}
        path={adminPages.schools.path}
        element={
          <LoginHandler user={user} types={[1]}>
            <ADMIN.SCHOOLS />
          </LoginHandler>
        }
      />
      <Route
        key={adminPages.workers.id}
        path={adminPages.workers.path}
        element={
          <LoginHandler user={user} types={[1]}>
            <ADMIN.WORKERS />
          </LoginHandler>
        }
      />
      <Route
        key={demoPages.forgotPassword.id}
        path={demoPages.forgotPassword.path}
        element={<FORGOT.FORGOT />}
      />
      <Route
        key={demoPages.resetForgotPassword.id}
        path={demoPages.resetForgotPassword.path}
        element={<FORGOT.FORGOT_RESET />}
      />

      <Route key={`error_404_page_route`} path='/404' element={<PAGE_404 />} />
      <Route path='*' element={<Navigate replace to='/404' />} />
    </Routes>
  );
};

export default ContentRoutes;
