import React from 'react';
import { demoPages } from '../menu';
import Footer from '../layout/Footer/Footer';

const footers = [
  { path: demoPages.login.path, element: null, exact: true },
  { path: demoPages.admin_login.path, element: null, exact: true },
  { path: demoPages.page404.path, element: null, exact: true },
  { path: demoPages.forgotPassword.path, element: null, exact: true },
  { path: demoPages.resetForgotPassword.path, element: null, exact: true },
  { path: '*', element: <Footer /> },
];

export default footers;
