import React, { useEffect } from 'react';
import { useTour } from '@reactour/tour';
import { createUseStyles } from 'react-jss';

import Button from './components/bootstrap/Button';
import DashboardIcon from './assets/img/dashboard_icon.png';
import TaskIcon from './assets/img/task_icon.png';
import SettingsIcon from './assets/img/settings_icon_1.png';
import TeacherIcon from './assets/img/teacher_icon_3.png';
import StudentIcon from './assets/img/students_icon_1.png';
import StudentAddIcon from './assets/img/students_icon_2.png';
import WeekScheduleIcon from './assets/img/week_schedule_icon.png';
import BasicScheduleIcon from './assets/img/basic_schedule_icon.png';
import CurrentScheduleIcon from './assets/img/current_schedule_icon.png';
import SubjectScheduleIcon from './assets/img/subject_schedule_icon.png';
import OverviewScheduleIcon from './assets/img/overview_schedule_icon.png';
import useChangeSecondaryColorBySchool from './hooks/school/useChangeSecondaryColorBySchool';
/**
 * Steps style
 */

export const styles = {
  /**
   * Overlay style
   * @param base
   */
  maskWrapper: (base: any) => ({ ...base }),
  maskArea: (base: any) => ({
    ...base,
    rx: 'var(--bs-border-radius)',
  }),
  highlightedArea: (base: any) => ({ ...base }),
  badge: (base: any) => ({ ...base }),
  popover: (base: any) => ({
    ...base,
    boxShadow: '0 0 3em rgba(0, 0, 0, 0.5)',
    backgroundColor: 'var(--bs-body-bg)',
    color: 'var(--bs-body-color)',
    borderRadius: 'var(--bs-border-radius)',
  }),
};

/**
 * Image Styles
 * @type {(data?: {theme?: DefaultTheme}) => Classes<"image">}
 */
const useStyles = createUseStyles({
  image: {
    maxHeight: '150px',
    objectFit: 'contain',
  },
});

/**
 * Prev & Next buttons
 * @returns {JSX.Element}
 * @constructor
 */
const TourNavigation = () => {
  const { currentStep, setCurrentStep } = useTour();
  useChangeSecondaryColorBySchool();
  useEffect(() => {
    setCurrentStep(0);
  }, []);
  return (
    <div className='col-12 mt-3'>
      <div className='row'>
        <div className='col'>
          {!!currentStep && (
            <Button
              icon='ArrowBackIos'
              color='danger'
              isLink
              onClick={() => setCurrentStep(currentStep - 1)}
            >
              Terug
            </Button>
          )}
        </div>
        <div className='col-auto'>
          <Button
            icon='ArrowForwardIos'
            color='danger'
            isLight
            onClick={() => setCurrentStep(currentStep + 1)}
          >
            {currentStep === 9 ? 'Sluiten' : 'Volgende'}
          </Button>
        </div>
      </div>
    </div>
  );
};

/**
 * Selector
 * @param name
 * @returns {`[data-tour='${string}']`}
 */
const getTargetName = (name: string): `[data-tour='${string}']` => {
  return `[data-tour='${name}']`;
};

const DarkModeTour = ({ src, title, description, reverse }: any) => {
  const classes = useStyles();
  return (
    <div className={reverse ? 'row flex-row-reverse m-2' : 'row m-2'}>
      <div className='col-md-5 mr-1'>
        <img src={src} className={classes.image} width='100%' alt='' />
      </div>

      <div className='col-md-7 d-flex align-items-center mt-3 mt-md-0'>
        <div>
          <p className='lead fw-bold fs-4'>{title}</p>
          <p>{description}</p>
        </div>
      </div>
      <TourNavigation />
    </div>
  );
};

/**
 * Tour Steps
 */
const steps = [
  /**
   * Dark Mode
   * @step 0
   */
  {
    selector: getTargetName('dashboard'),
    content: () => (
      <DarkModeTour
        src={DashboardIcon}
        title={'Dashboard'}
        description={
          'Op het dashboard kan je de algemene taken, komende verjaardagen, en aankomende vakanties bekijken!'
        }
      />
    ),
  },
  /**
   * List Page
   * @step 1
   */
  {
    selector: getTargetName('schedule-current'),
    content: () => (
      <DarkModeTour
        src={CurrentScheduleIcon}
        title={'Huidige rooster'}
        description={
          'Je kan jouw huidige weekrooster in detail bekijken voor elke dag met de toegewezen lessen.'
        }
      />
    ),
  },

  /**
   * Date Range
   * @step 2
   */
  {
    selector: getTargetName('schedule-overview'),
    content: () => (
      <DarkModeTour
        src={OverviewScheduleIcon}
        title={'Overzicht'}
        description={'Hier kan je een overzicht zien van alle weekroosters.'}
        reverse={true}
      />
    ),
  },

  /**
   * Edit Page
   * @step 3
   */
  {
    selector: getTargetName('homework'),
    content: () => (
      <DarkModeTour
        src={TaskIcon}
        title={'Taken'}
        description={
          'Je kan hier een taak maken voor een specifieke week/dag met een geselecteerde vak en/of met een niveau.'
        }
      />
    ),
  },
  /**
   * Lang Selector
   * @step 4
   */
  {
    selector: getTargetName('workers_overview'),
    content: () => (
      <DarkModeTour
        src={StudentIcon}
        title={'Werkers Overzicht'}
        description={
          'Hier kan je al je werkers bekijken en hun niveaus toewijzen.'
        }
        reverse={true}
      />
    ),
  },
  /**
   * Search
   * @step 5
   */
  {
    selector: getTargetName('add_worker'),
    content: () => (
      <DarkModeTour
        src={StudentAddIcon}
        title={'Werker Toevoegen'}
        description={'Je kan hier een werker toevoegen'}
      />
    ),
  },

  /**
   * Edit Page
   * @step 6
   */ {
    selector: getTargetName('settings'),
    content: () => (
      <DarkModeTour
        src={SettingsIcon}
        title={'Instellingen'}
        description={
          'Wijzig hier een aantal instellingen. Je kan onder andere de groepsgegevens, het totaal aantal leraren en vele andere dingen bekijken.'
        }
        reverse={true}
      />
    ),
  },

  /**
   * Filter
   * @step 7
   */
  {
    selector: getTargetName('schedule-week'),
    content: () => (
      <DarkModeTour
        src={WeekScheduleIcon}
        title={'Weekrooster'}
        description={
          'Je kan je rooster in detail bekijken voor elke dag van de geselecteerde week met de toegewezen lessen.'
        }
        reverse={true}
      />
    ),
  },
  /**
   * List
   * @step 8
   */
  {
    selector: getTargetName('schedule-basic'),
    content: () => (
      <DarkModeTour
        src={BasicScheduleIcon}
        title={'Basisrooster'}
        description={
          'Op deze pagina kan je een basisrooster bekijken/wijzigen. Dit is het standaard rooster voor elke week.'
        }
      />
    ),
  },
  /**
   * Grid Page
   * @step 9
   */
  {
    selector: getTargetName('schedule-subject'),
    content: () => (
      <DarkModeTour
        src={SubjectScheduleIcon}
        title={'Vaklesrooster'}
        description={
          'Je kan hier het vakkenrooster zien waarmee je de leerkrachten kan toewijzen aan de vakken van een bepaalde week/tijd.'
        }
        reverse={true}
      />
    ),
  },
];

export default steps;
