export const dashboardMenu = {
  dashboard: {
    id: 'dashboard',
    text: 'Home',
    path: '/',
    icon: 'Home',
    dataTour: 'dashboard',
  },
};

export const studentsMenu = {
  workers: {
    id: 'workers',
    text: 'Werkers',
    icon: 'SupervisorAccount',
    dataTour: 'workers',
  },
  overview: {
    id: 'workers_overview',
    text: 'Werkers Overzicht',
    path: 'workers',
    icon: 'SupervisorAccount',
    dataTour: 'workers_overview',
  },
  add_worker: {
    id: 'add_worker',
    text: 'Werker Toevoegen',
    path: 'add-worker',
    icon: 'PlusCircle',
    dataTour: 'add_worker',
  },
};

export const settingsMenu = {
  Settings: {
    id: 'settings',
    text: 'Beheer',
    icon: 'Settings',
  },
  setting: {
    id: 'setting',
    text: 'Instellingen',
    path: 'settings',
    icon: 'Settings',
    dataTour: 'settings',
  },
  weekschedule: {
    id: 'weekschedule',
    text: 'Weekrooster',
    path: 'weekschedule',
    icon: 'DateRange',
    dataTour: 'schedule-week',
    subMenu: null,
  },
  basisschedule: {
    id: 'basisschedule',
    text: 'Basisrooster',
    path: 'basisschedule',
    icon: 'CalendarToday',
    dataTour: 'schedule-basic',
    subMenu: null,
  },
  subjectschedule: {
    id: 'subjectschedule',
    text: 'Vaklesrooster',
    path: 'subject-schedule',
    icon: 'EventNote',
    dataTour: 'schedule-subject',
    subMenu: null,
  },
};

export const workersMenu = {
  workers: {
    id: 'workers',
    text: 'Medewerkers',
    icon: 'SupervisorAccount',
    dataTour: 'workers',
  },
  currentschedule: {
    id: 'currentschedule',
    text: 'Huidige rooster',
    path: 'current-schedule',
    icon: 'Today',
    dataTour: 'schedule-current',
  },
  overview: {
    id: 'overview',
    text: 'Overzicht',
    path: 'schedule-overview',
    icon: 'CalendarViewMonth',
    dataTour: 'schedule-overview',
  },
  overviewHomework: {
    id: 'homework',
    text: 'Taken',
    path: 'homework',
    icon: 'Article',
    dataTour: 'homework',
  },
};

export const homeworkMenu = {
  homework: {
    id: 'homework',
    text: 'Taken',
    icon: 'Feed',
  },
};

export const homeMenu = {
  intro: {
    id: 'intro',
    text: 'Intro',
    path: '#intro',
    icon: 'Vrpano',
    subMenu: null,
  },
  bootstrap: {
    id: 'bootstrap',
    text: 'Bootstrap Components',
    path: '#bootstrap',
    icon: 'BootstrapFill',
    subMenu: null,
  },
  storybook: {
    id: 'storybook',
    text: 'Storybook',
    path: '#storybook',
    icon: 'CustomStorybook',
    subMenu: null,
  },
  formik: {
    id: 'formik',
    text: 'Formik',
    path: '#formik',
    icon: 'CheckBox',
    subMenu: null,
  },
  apex: {
    id: 'apex',
    text: 'Apex Charts',
    path: '#apex',
    icon: 'AreaChart',
    subMenu: null,
  },
};

export const scheduleMenu2 = {
  Roosters: {
    id: 'schedules',
    text: 'Roosters',
    path: 'schedule-overview',
    icon: 'DateRange',
    subMenu: null,
  },
  overview: {
    id: 'overview',
    text: 'Overzicht',
    path: 'schedule-overview',
    icon: 'CalendarViewMonth',
    dataTour: 'schedule-overview',
    subMenu: null,
  },
  currentschedule: {
    id: 'currentschedule',
    text: 'Huidige rooster',
    path: 'current-schedule',
    icon: 'Today',
    dataTour: 'schedule-current',
    subMenu: null,
  },
  weekschedule: {
    id: 'weekschedule',
    text: 'Weekrooster',
    path: 'weekschedule',
    icon: 'DateRange',
    dataTour: 'schedule-week',
  },
  basisschedule: {
    id: 'basisschedule',
    text: 'Basisrooster',
    path: 'basisschedule',
    icon: 'CalendarToday',
    dataTour: 'schedule-basic',
  },
  subjectschedule: {
    id: 'subjectschedule',
    text: 'Vaklesrooster',
    path: 'subject-schedule',
    icon: 'EventNote',
    dataTour: 'schedule-subject',
  },
};

export const headerDropMenu = {
  Roosters: {
    id: 'schedules',
    text: 'Medewerkers',
    icon: 'DateRange',
  },
  currentschedule: {
    id: 'currentschedule',
    text: 'Huidige rooster',
    path: 'current-schedule',
    icon: 'Today',
    dataTour: 'schedule-current',
    subMenu: null,
  },
  overview: {
    id: 'overview',
    text: 'Overzicht',
    path: 'schedule-overview',
    icon: 'CalendarViewMonth',
    dataTour: 'schedule-overview',
    subMenu: null,
  },
  overviewHomework: {
    id: 'homework',
    text: 'Taken',
    path: 'homework',
    icon: 'Article',
    dataTour: 'homework',
  },
  weekschedule: {
    id: 'weekschedule',
    text: 'Weekrooster',
    path: 'weekschedule',
    icon: 'DateRange',
    dataTour: 'schedule-week',
  },
  basisschedule: {
    id: 'basisschedule',
    text: 'Basisrooster',
    path: 'basisschedule',
    icon: 'CalendarToday',
    dataTour: 'schedule-basic',
  },
  subjectschedule: {
    id: 'subjectschedule',
    text: 'Vaklesrooster',
    path: 'subject-schedule',
    icon: 'EventNote',
    dataTour: 'schedule-subject',
  },
};

export const adminPages = {
  workers: {
    id: 'workers',
    text: 'Medewerker',
    path: 'workers-admin',
    icon: 'SupervisorAccount',
  },
  schools: {
    id: 'schools',
    text: 'Scholen',
    path: 'schools',
    icon: 'School',
  },
};

export const demoPages = {
  auth: {
    id: 'auth',
    text: 'Auth Pages',
    icon: 'Extension',
  },
  login: {
    id: 'login',
    text: 'Login',
    path: 'login',
    icon: 'Login',
  },
  admin_login: {
    id: 'admin_login',
    text: 'Admin Login',
    path: 'admin-login',
    icon: 'Login',
  },
  forgotPassword: {
    id: 'forgotPassword',
    text: 'Wachtwoord vergeten',
    path: 'wachtwoord-vergeten',
    icon: 'Login',
  },
  resetForgotPassword: {
    id: 'resetForgotPassword',
    text: 'Wachtwoord vergeten',
    path: 'wachtwoord-vergeten/:id',
    icon: 'Login',
  },
  page404: {
    id: 'Page404',
    text: '404 Page',
    path: '404',
    icon: 'ReportGmailerrorred',
  },
};
