import toast from 'react-hot-toast';
import showNotification from '../../../components/extras/showNotification';
import Icon from '../../../components/icon/Icon';

export const toastSuccess = (message?: string) => {
  showNotification(
    <span className='d-flex align-items-center p-3'>
      <Icon icon='CheckCircle' size='2x' className='me-3' />
      <span className='fs-6 ml-2'>{message || 'Successvol!'}</span>
    </span>,
    '',
    'success'
  );
};

export const toastError = (message?: string) => {
  showNotification(
    <span className='d-flex align-items-center p-3'>
      <Icon icon='Warning' size='2x' className='me-3' />
      <span className='fs-6 ml-2'>
        {message ||
          'Er is een onverwachte fout opgetreden, probeer het later nog eens.'}
      </span>
    </span>,
    '',
    'danger'
  );
};

export const toastWarning = (message?: string) => {
  toast.error('');
};
