import { dashboardMenu, demoPages, settingsMenu } from '../menu';
import SummaryHeader from '../pages/common/Headers/SummaryHeader';

const headers = [
  { path: demoPages.login.path, element: null, exact: true },
  { path: demoPages.admin_login.path, element: null, exact: true },
  { path: demoPages.page404.path, element: null, exact: true },
  { path: demoPages.forgotPassword.path, element: null, exact: true },
  { path: demoPages.resetForgotPassword.path, element: null, exact: true },
  {
    path: dashboardMenu.dashboard.path,
    element: <SummaryHeader />,
    exact: true,
  },
  {
    path: settingsMenu.setting.path,
    element: <SummaryHeader />,
    exact: true,
  },
  {
    path: `*`,
    element: <SummaryHeader />,
  },
];

export default headers;
