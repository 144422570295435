import { TourProvider } from '@reactour/tour';
import axios from 'axios';
import { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { ThemeProvider } from 'react-jss';
import { ReactNotifications } from 'react-notifications-component';
import { Route, Routes } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { useFullscreen } from 'react-use';
import COLORS from '../common/data/enumColors';
import { Toast, ToastContainer } from '../components/bootstrap/Toasts';
import { UserProvider } from '../components/klassenrooster/UserContext';
import ThemeContext from '../contexts/themeContext';
import { getOS } from '../helpers/helpers';
import useDarkMode from '../hooks/useDarkMode';
import Aside from '../layout/Aside/Aside';
import Portal from '../layout/Portal/Portal';
import Wrapper from '../layout/Wrapper/Wrapper';
import { demoPages } from '../menu';
import steps, { styles } from '../steps';

axios.defaults.withCredentials = true;
const App = () => {
  getOS();

  /**
   * Dark Mode
   */

  // Accessing scss variable "--background-color"
  // and "--text-color" using plain JavaScript
  // and changing the same according to the state of "darkTheme"
  const root = document.documentElement;
  root?.style.setProperty('--danger', 'black', 'important');
  root?.style.setProperty('--bs-btn-hover-bg', 'black', 'important');
  root?.style.setProperty('--bs-btn-bg', 'black', 'important');
  root?.style.setProperty('--bs-btn-border-color', 'black', 'important');

  //const { user } = useContext(UserContext);

  const { themeStatus, darkModeStatus } = useDarkMode();
  const theme = {
    theme: themeStatus,
    primary: COLORS.PRIMARY.code,
    secondary: COLORS.SECONDARY.code,
    success: COLORS.SUCCESS.code,
    info: COLORS.INFO.code,
    warning: COLORS.WARNING.code,
    danger: COLORS.DANGER.code,
    dark: COLORS.DARK.code,
    light: COLORS.LIGHT.code,
  };

  useEffect(() => {
    if (darkModeStatus) {
      document.documentElement.setAttribute('theme', 'dark');
    }
    return () => {
      document.documentElement.removeAttribute('theme');
    };
  }, [darkModeStatus]);

  /**
   * Full Screen
   */
  // @ts-ignore
  const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
  const ref = useRef(null);
  useFullscreen(ref, fullScreenStatus, {
    onClose: () => setFullScreenStatus(false),
  });

  /**
   * Modern Design
   */
  useLayoutEffect(() => {
    if (process.env.REACT_APP_MODERN_DESGIN === 'true') {
      document.body.classList.add('modern-design');
    } else {
      document.body.classList.remove('modern-design');
    }
  });

  //	Add paths to the array that you don't want to be "Aside".
  const withOutAsidePages = [
    demoPages.login.path,
    demoPages.admin_login.path,
    demoPages.resetForgotPassword.path,
    demoPages.forgotPassword.path,
    demoPages.page404.path,
  ];

  return (
    <UserProvider>
      <ThemeProvider theme={theme}>
        <ToastProvider components={{ ToastContainer, Toast }}>
          <TourProvider
            steps={steps}
            styles={styles}
            showNavigation={false}
            showBadge={false}
          >
            <div
              ref={ref}
              className='app'
              style={{
                backgroundColor: fullScreenStatus
                  ? 'var(--bs-body-bg)'
                  : undefined,
                zIndex: fullScreenStatus ? 1 : undefined,
                overflow: fullScreenStatus ? 'scroll' : undefined,
              }}
            >
              <Routes>
                {withOutAsidePages.map((path) => (
                  <Route key={path} path={path} />
                ))}
                <Route path='*' element={<Aside />} />
              </Routes>
              <Wrapper />
            </div>
            <Portal id='portal-notification'>
              <ReactNotifications />
            </Portal>
          </TourProvider>
        </ToastProvider>
      </ThemeProvider>
    </UserProvider>
  );
};

export default App;
