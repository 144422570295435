import React, { FC, useContext } from 'react';
import PropTypes from 'prop-types';
import logo from '../assets/logos/wplogo.png';
import { UserContext } from '../components/klassenrooster/UserContext';
import SchoolLogo from '../assets/img/school_logo.jpg';
import KlassenLogo from '../assets/img/logo-faded-blue.png';
import { API_URL } from '../constants/';
interface ILogoProps {
  width?: number;
  height?: number;
  borderRadius?: number;
}
const Logo: FC<ILogoProps> = ({ width, height, borderRadius }) => {
  const { user } = useContext(UserContext);

  if (user?.logo_url) {
    return (
      <img
        width={height !== 80 && !!height ? height * (350 / 80) : width}
        height={width !== 350 && !!width ? width * (80 / 350) : height}
        style={{ borderRadius: borderRadius ? borderRadius : 0 }}
        src={`${API_URL}/school/${user?.logo_url}`}
      />
    );
  } else {
    return <></>;
  }
};
Logo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  borderRadius: PropTypes.number,
};
Logo.defaultProps = {
  width: 350,
  height: 80,
  borderRadius: 0,
};

export default Logo;
