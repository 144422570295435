import { UserContext } from '../../components/klassenrooster/UserContext';
import React, { useEffect, useState, useContext } from 'react';

const useChangeSecondaryColorBySchool = () => {
  const { user } = useContext(UserContext);

  useEffect(() => {
    document
      .querySelectorAll('.bg-danger')
      .forEach((el: any) =>
        el.style.setProperty(
          'background-color',
          user?.secondary_color,
          'important'
        )
      );
    const root = document.documentElement;
    root?.style.setProperty(
      '--bs-danger-rgb',
      user?.secondary_color,
      'important'
    );

    document
      .querySelectorAll('.btn-danger')
      .forEach(
        (el: any) => (
          (el.style.backgroundColor = user?.secondary_color),
          (el.style.borderColor = user?.secondary_color),
          (el.style.color = 'white')
        )
      );

    document
      .querySelectorAll('.btn-light-danger')
      .forEach(
        (el: any) => (
          (el.style.backgroundColor = user?.secondary_color),
          (el.style.borderColor = user?.secondary_color),
          (el.style.color = 'white')
        )
      );

    document
      .querySelectorAll('.bg-l10-danger')
      .forEach((el: any) =>
        el.style.setProperty(
          'background-color',
          user?.secondary_color,
          'important'
        )
      );

    document
      .querySelectorAll('.bg-l10-danger')
      .forEach((el: any) =>
        el.style.setProperty('color', 'white', 'important')
      );

    document
      .querySelectorAll('.text-danger')
      .forEach((el: any) =>
        el.style.setProperty('color', user?.secondary_color, 'important')
      );

    document
      .querySelectorAll('.btn.btn-outline-danger')
      .forEach((el: any) =>
        el.style.setProperty('color', user?.secondary_color, 'important')
      );

    document
      .querySelectorAll('.fa-icon')
      .forEach((el: any) =>
        el.style.setProperty(
          'color',
          user?.primary_color ? user?.primary_color : 'red',
          'important'
        )
      );
  }, [user]);
};

export default useChangeSecondaryColorBySchool;
