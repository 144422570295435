import React, { createContext, useEffect, useState } from 'react';
import Spinner from '../../components/bootstrap/Spinner';

import axios from 'axios';
import { API_URL } from '../../constants';

export type UserContextType = any | null | undefined;

export interface UserContextState {
  user?: UserContextType;
  setUser: React.Dispatch<React.SetStateAction<UserContextType>>;
  refreshUser: () => void;
}

interface UserProviderProps {
  children: JSX.Element;
}

export const UserContext = createContext<UserContextState>(undefined!);

export function UserProvider(props: UserProviderProps): JSX.Element {
  const { children } = props;
  const [userContext, setUser] = useState<UserContextType>(undefined);

  const getCurrentUser = async () => {
    try {
      const userInfo = await axios.get(`${API_URL}/teacher/me`);

      setUser(userInfo.data ? userInfo.data : null);
    } catch (e: any) {
      setUser(null);
    }
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  if (userContext === undefined) {
    // Do not load the children of the provider if the user is undefined.
    // Lets further wait...
    // undefined = we have not determined the login state yet
    // null = not logged in
    // User value = logged in
    return (
      <div className='d-flex justify-content-center align-items-center min-vh-100'>
        <Spinner className='me-2' color='danger' />
      </div>
    );
  }

  return (
    <UserContext.Provider
      value={{
        user: userContext,
        setUser,
        refreshUser: getCurrentUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
