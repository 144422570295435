import React, { useContext } from 'react';
import { useMeasure } from 'react-use';
import classNames from 'classnames';
import useDarkMode from '../../hooks/useDarkMode';
import { UserContext } from '../../components/klassenrooster/UserContext';

const Footer = () => {
  const { user } = useContext(UserContext);
  const [ref, { height }] = useMeasure<HTMLDivElement>();

  const root = document.documentElement;
  root.style.setProperty('--footer-height', `${height}px`);

  const { darkModeStatus } = useDarkMode();

  return (
    <footer ref={ref} className='footer'>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col'>
            <span className='fw-light'>
              Copyright © {new Date().getFullYear()}
            </span>
          </div>
          <div className='col-auto'>
            <a
              href='/'
              className={classNames('text-decoration-none', {
                'link-dark': !darkModeStatus,
                'link-light': darkModeStatus,
              })}
            >
              <small className='fw-bold'>Klassenrooster</small>{' '}
              {user?.name && <small className='fw-bold'>- {user?.name}</small>}
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
